
import Agents from "~/graphql/Portfolio/Agents.gql";
import PropertySales from "~/graphql/Portfolio/PropertySales.gql";
import PropertyAgentValuations from "~/graphql/Portfolio/PropertyAgentValuations.gql";

import ViewSales from "~/pages/portfolio/components/Sales/ViewSales.vue";

import { InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import { getPropertyArea } from "~/helpers/portfolio/portfolio-property-helpers";
import { getLatestSalesFinances } from "~/helpers/portfolio/portfolio-sales-helpers";
import { TenancyDecomissionedFilter, TenancySoldFilter } from "~/helpers/apollo/apollo-portfolio-filter-helpers";

export default {
  components: { ViewSales },

  inheritAttrs: false,

  props: {
    propertyId: {
      type: String,
      required: true,
    },
  },

  apollo: {
    agents: {
      query: Agents,
      update(data) {
        return data.assetManagementAgents.items;
      },
    },

    property: {
      query: PropertySales,
      variables() {
        return new InputBuilder().setId(this.propertyId).setFilter(new TenancyDecomissionedFilter()).setFilter(new TenancySoldFilter()).build();
      },
      update(data) {
        return data.assetManagementProperty;
      },
    },

    propertyValuations: {
      query: PropertyAgentValuations,
      variables() {
        return new InputBuilder()
          .setId(this.propertyId)
          .setFilter({
            matches: [{ agent_type: ["in", ["BROKER", "broker"]] }],
          })
          .build();
      },
      update(data) {
        return data.assetManagementProperty.assetManagementValuations.items;
      },
    },
  },

  computed: {
    salesLoading() {
      return this.$apollo.queries.property.loading;
    },

    valuationsLoading() {
      return this.$apollo.queries.propertyValuations.loading;
    },

    agentsLoading() {
      return this.$apollo.queries.agents.loading;
    },

    propertySales() {
      return this.property?.assetManagementSales?.items || [];
    },

    propertyArea() {
      if (!this.property) return null;

      return getPropertyArea(this.property);
    },

    salesFinances() {
      // For now, we only have one sale per property
      return getLatestSalesFinances(this.propertySales);
    },

    valuations() {
      return this.propertyValuations || [];
    },
  },

  methods: {
    updateSales() {
      this.$apollo.queries.property.refetch();
    },

    updateValuations() {
      this.$apollo.queries.propertyValuations.refetch();
    },
  },
};
